import { Outlet, Link } from "react-router-dom";
import { Row } from "./components/Row";
import { Column } from "./components/Column";
import { styled } from "styled-components";
import { Emphasis } from "./components/Typography";

const Container = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  min-width: 615px;
`;

const HeaderRow = styled(Row)`
  justify-content: space-between;
  padding-bottom: 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
  color: white;
`;

const TopNavRow = styled(Row)`
  gap: 1rem;
  align-items: center;
`;

const OutletRow = styled(Row)`
  flex: 1 1 auto;
  position: relative;
  min-width: 1px;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
`;

const LinkText = styled(Link)`
  font-family: Lato, Helvetica, sans-serif;
  font-weight: 600;
  color: #000;
  line-height: 24px;
  color: rgb(33, 33, 33);
  text-decoration-color: rgb(33, 33, 33);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
  &:hover {
    text-decoration: underline;
  }
`;

const topNavRoutes: { path: string; name: string }[] = [
  { path: "/", name: "Home" },
  { path: "/about", name: "About" },
  { path: "/posts", name: "Posts" },
  { path: "/cat", name: "Cat" },
];

export const Layout = () => {
  return (
    <Container>
      <HeaderRow>
        <Column>
          <TopNavRow style={{ marginTop: "4px" }}>
            <Emphasis>
              <LinkText to="/">{"SANTERRE.XYZ"}</LinkText>
            </Emphasis>
          </TopNavRow>
        </Column>
        <Column>
          <TopNavRow>
            {topNavRoutes.map(({ name, path }) => {
              return (
                <LinkText key={path} to={path}>
                  {name}
                </LinkText>
              );
            })}
          </TopNavRow>
        </Column>
      </HeaderRow>
      <OutletRow>
        <Outlet />
      </OutletRow>
    </Container>
  );
};
