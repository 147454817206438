import { CircularImage } from "../components/CircularImage";
import cat from "../assets/cat.jpg";
import { styled } from "styled-components";
import { Row } from "../components/Row";

const Container = styled(Row)`
  justify-content: center;
`;

const CatImage = styled(CircularImage)`
  width: 50%;
  min-width: 200px;
`;

export const Cat = () => {
  return (
    <Container>
      <CatImage src={cat}></CatImage>
    </Container>
  );
};
