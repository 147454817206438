import { styled } from "styled-components";

const Base = styled.div`
  font-size: 24px;
  font-family: Lato, Helvetica, sans-serif;
  font-weight: 700;
  color: #1f1b24;
  line-height: 24px;
`;

export const Title = styled(Base)`
  font-size: 2rem;
  line-height: 3.6rem;
`;

export const Subtitle = styled(Base)`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 3.2rem;
`;

export const SectionTitle = styled(Base)`
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 3.2rem;
`;

export const Body = styled(Base)`
  font-size: 1rem;
  line-height: 24px;
  font-weight: 500;
`;

export const Emphasis = styled(Base)`
  font-weight: bold;
`;
