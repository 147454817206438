import { Body, Subtitle } from "../../components/Typography";

export const BasicsAndAdvancedTemporalDesignPatterns = () => {
  return (
    <>
      <Subtitle>Who what when where and why is Temporal</Subtitle>
      <Body>
        Temporal is a fairly new (at least at the time of writing) software
        built for orchestrating workflows and asynchronous tasks. It's a bit
        like a cron job, but with a lot more power and flexibility. Having now
        used Temporal for the last 2 years at Borderless, we have come to know
        it's positive and negatives, and overall have learned a lot about how to
        use it effectively.
      </Body>
      <Subtitle>Temporal Basics</Subtitle>
      <Body>
        This post isn't meant to rewrite the Temporal docs, so I'm only going to
        go over the core concepts. Temporal has two core concepts: Workflows and
        Activities. Workflows are designed to hold the logic of your
        application, and Activities are designed to be the building blocks of
        your workflows. Workflows are long running, and Activities are short
        running. Workflows can call Activities, workflows can also call other
        workflows. Workflows have a core concept of{" "}
        <b>deterministic execution</b>. If you take away anything from this,
        it's that this is where you will encounter the most trouble and where
        you will spend the most time educating your team. This constraint in
        practice means that you can't use things like Math.random, or Date.now()
        in your workflows. This is because if you were to replay a workflow, you
        would need to be able to guarantee that the same output would be
        produced.
      </Body>
      <Body>
        The other core concept in Temporal is the idea of an activity. As the
        documentation puts it, this is where your unsafe code goes! Anything you
        aren't allowed to do in a workflow can happen here. Given the freedom of
        this code, you need to code them a certain way. They <i>should</i> be
        idempotent, and they must assume they will be rerun at any time.
      </Body>
      <Subtitle>Pattern #1: Generator & Processor</Subtitle>
      <Body>
        The first pattern I want to talk about came about from a need for a
        queue like system. In our case we needed to process thousands of global
        payroll/invoices which involved a whole lot of forex, compliance, and
        more checks and balances.
      </Body>
      <Body>
        The architecture of this pattern is quite simple. We have 3 key pieces,
        the first being an API that populates rows in the "invoices" table, the
        second being a workflow that "generates" invoice payout attempts and the
        third being a workflow that "processes" these attempts.
      </Body>
      <Body>More to come on this topic in the future...</Body>
    </>
  );
};
