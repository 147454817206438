import { Body, Subtitle } from "../../components/Typography";
import Gist from "react-gist";

export const TwoYearsOfLessonsWithTypescriptMonorepositories = () => {
  return (
    <>
      <Subtitle>Introduction</Subtitle>
      <Body>
        At Borderless, we are primarily a Typescript shop, focusing our efforts
        on leveraging shared code and types between our browser and server
        applications. I'm not going to get into the benefits of sharing a
        language between your different deployed applications, but suffice it to
        say that it works great for us! In this post I want to run through the
        different problems, solutions and walk you through how we overcame them
        to get to our current solution.
      </Body>
      <Body>
        The goals we have with our repository architecture can be summarized
        into the following:
        <ul>
          <li>
            KISS Principle: Keeping the structure simple will aid in both
            maintainability but also reduce barriers to entry for newcomers to
            the codebase
          </li>
          <li>
            Performant Builds: Reducing build time across the project will allow
            us to deploy quickly and make changes to production just as quick -
            Automate the Boring Things: Creating templates or code generation
            for the tasks that are both prone to human error and a waste of our
            creative time
          </li>
          <li>
            Consistency Across Packages: Do one thing the same way, across all
            of our maintained packages
          </li>
        </ul>
      </Body>
      <Subtitle>Our First Iteration</Subtitle>
      <Body>
        Given that Borderless is a startup, we had the fortune of not being tied
        down by the maintenance or migration of legacy code. This meant that we
        were able to take lessons learnt from past projects and apply them to
        our new ones. One of these such lessons learned was the organization and
        deployment of code to production or prod like environments like our
        QA/Demo environments.
      </Body>
      <Body>
        The initial implementation followed some simple rules that guided us:
        <ul>
          <li>
            All projects, regardless of language, deployment method, target
            device will be developed in the monorepo
          </li>
          <li>
            All projects will be able to run on a developer's laptop, no manual
            steps necessary (beyond a standard cli command to start it that
            is...)
          </li>
          <li>
            Code that is merged to our main branch will automatically be
            deployed to production if it passes the validation suite
          </li>
        </ul>
      </Body>
      <Body>
        Armed with this, we began the project with a structure outlined below:
      </Body>
      <Gist id="1c0671a3686f1a49a04ed8c8abff0472" />
      <Body>
        Some questions that we asked ourselves, and others asked us with this
        architecture:
      </Body>
      <Body>
        <b>Why split instead keeping all packages in one folder?</b>
        <div>
          At the cost of increased complexity, we gain a level of rigidity in
          how packages are setup. We define an "app" as any independently
          deployable package, and as such have more structure around it for
          configuration, entrypoints, developer environment setup.
        </div>
      </Body>
      <Body>
        <b>
          Why not maintain a single package.json at the top level of the
          repository?
        </b>
        <div>
          Great question! For our typescript codebase, this was a key decision
          to help enforce module boundaries and assist with answering developers
          question of "Should I just import this from this other package?". With
          this structure we take advantage of NPM workspaces, each app or
          package is treated as it's own locally hosted NPM package. Check out
          more about NPM workspaces{" "}
          <a href="https://docs.npmjs.com/cli/v7/using-npm/workspaces">here</a>.
        </div>
      </Body>
      <Body>
        As for our package.json structure, below is an example of what our API
        "app" looks like.
      </Body>
      <Subtitle>The Build Pipeline</Subtitle>
      <Body>
        One of the larger challenges with this monorepo structure is the fact
        that in order to build the entire project, all dependent packages also
        need to be transpiled. Not only is this a problem with transpilation,
        but also for tasks like linting and testing. Fortunately for us we
        started the project with an integration with{" "}
        <a href="https://turbo.build/" target="_blank">
          Turborepo
        </a>
        . This tool at the time was still fairly new on the scene and had some
        changes but overall has been incredibly stable. The main benefit we took
        advantage of is the caching of task outputs across our packages. Tying
        back to the previous section, the fact that all of our packages were
        standard in how they were setup, meant that the repository was extremely
        well suited to a tool like this.
      </Body>
      <Body>More to come on this topic in the future...</Body>
    </>
  );
};
