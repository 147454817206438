import styled from "styled-components";
import { Column } from "../components/Column";
import { Body, Subtitle, Title } from "../components/Typography";
import { ReactNode } from "react";
import { Link as _Link, useParams } from "react-router-dom";
import { BasicsAndAdvancedTemporalDesignPatterns } from "./posts/BasicsAndAdvancedTemporalDesignPatterns";
import { TwoYearsOfLessonsWithTypescriptMonorepositories } from "./posts/TwoYearsfLessonsWithTypescriptMonorepositories";
import { GitlabDependencyCaching } from "./posts/GitlabDependencyCaching";

const Container = styled(Column)`
  gap: 8px;
`;

const Link = styled(_Link)`
  color: #000;
  text-decoration-color: rgb(33, 33, 33);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
  &:hover {
    text-decoration: underline;
  }
`;

const posts: {
  id: string;
  title: string;
  brief: string;
  content: ReactNode;
  tags: string[];
}[] = [
  {
    id: "two-years-of-typescript",
    title: "Two Years of Lessons with a Typescript Monorepo",
    brief:
      "In the last two years of finally having the chance to work with typescript, I'll walk you through the challenges and experiences that I have encountered.",
    content: <TwoYearsOfLessonsWithTypescriptMonorepositories />,
    tags: [],
  },
  {
    id: "temporal-design-patterns",
    title: "Basics and Advanced Temporal Design Patterns",
    brief:
      "Having the chance to work with a technology like Temporal has been very enlightening. Though we use it for nearly all of our async tasks at Borderless, there are some common patterns we have reused that serve us well to this day!",
    content: <BasicsAndAdvancedTemporalDesignPatterns />,
    tags: [],
  },
  {
    id: "gitlab-dependency-caching",
    title: "Speeding up Gitlab Dependency Caching",
    brief:
      "A quick guide on how to speed up your gitlab dependency caching beyond what the documentation provides.",
    content: <GitlabDependencyCaching />,
    tags: [],
  },
];

/**
 * TODO:
 * - how to speed up gitlab dependency caching
 * - things I wish I knew learning typescript+monorepo
 * - temporal patterns and gotchas
 */
export const Posts = () => {
  const { postId } = useParams();
  const post = posts.find(({ id }) => String(id) === postId);

  if (post) {
    return (
      <Container>
        <Title>{post.title}</Title>
        {post.content}
      </Container>
    );
  }

  return (
    <Container>
      <Title>Posts</Title>
      {posts.map(({ id, title, brief }) => {
        return (
          <div key={id}>
            <Link to={`/posts/${id}`}>
              <Subtitle>{title}</Subtitle>
            </Link>
            <Body>{brief}</Body>
          </div>
        );
      })}
    </Container>
  );
};
