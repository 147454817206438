import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Root } from "./routes/Root.tsx";
import { Posts } from "./routes/Posts";
import { Layout } from "./Layout.tsx";
import { Cat } from "./routes/Cat.tsx";
import { About } from "./routes/About.tsx";
import { init as sentryInit } from "@sentry/react";

sentryInit({
  dsn: "https://642f48809359ef29108fe0ffc9604824@o515493.ingest.sentry.io/4505982296260608",
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path="/" element={<Root />} />
      <Route path="/about" element={<About />} />
      <Route path="/posts" element={<Posts />} />
      <Route path="/posts/:postId" element={<Posts />} />
      <Route path="/cat" element={<Cat />} />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
