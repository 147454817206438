import { styled } from "styled-components";
import { Column } from "../components/Column";
import { Body, Subtitle, Title } from "../components/Typography";

const Container = styled(Column)`
  gap: 8px;
`;

export const About = () => {
  return (
    <Container>
      <Title>About Me</Title>
      <Subtitle>General Interests</Subtitle>
      <Body></Body>
      <Body>Hi there 👋</Body>
      <Body>
        Welcome to my corner of the digital world! I'm Thomas, a passionate Full
        Stack Engineer with over a decade of experience in the tech industry.
        I'm an avid traveler, cyclist, reader and gaming enthusiast, these days
        you'll mostly find me chasing my cat off of a tabletop game, trying to
        read through Robert Jordan's Wheel of Time in a timely manner, or
        building cities in City Skylines.
      </Body>
      <Body>
        I'm Thomas, a software engineer with a passion for innovation and a
        global perspective. While my roots trace back to New Brunswick, Canada,
        I've spent a significant part of my life in Toronto. It was here that I
        embarked on my journey, pursuing a degree in Electrical Engineering at
        the University of Toronto. My academic journey not only equipped me with
        technical expertise but also instilled in me a curiosity for the world's
        ever-evolving technology landscape.
      </Body>
      <Body>
        This curiosity, along with my experience travelling across the globe,
        led me to the vibrant city of Tokyo, where I had the privilege of
        experiencing firsthand the cutting-edge advancements in the fintech
        industry. After my enriching experience in Tokyo, I returned to,
        Toronto, driven by a mission to apply my knowledge and skills to create
        meaningful solutions.
      </Body>
      <Body>
        My professional journey has been marked by a commitment to innovation,
        problem-solving, and a deep appreciation for the intersection of culture
        and technology. Beyond my engineering pursuits, I am a firm believer in
        lifelong learning and am constantly exploring new horizons. Whether it's
        delving into the latest developments in software or immersing myself in
        diverse cultures, I thrive on the journey of discovery.
      </Body>
      <Subtitle>Professional Details</Subtitle>
      <Body>
        My journey into the realm of programming began two decades ago, and it's
        been an exhilarating ride ever since.
      </Body>
      <Body>
        Originally, I set out to become an Electrical Engineer, but turns out
        that I was not nearly as interested in how circuits are put together in
        CAD compared to iterating on a software problem. Encouraged by inspiring
        professors and supportive colleagues, I embraced my natural aptitude for
        distributed systems, and the world of software development quickly
        became my new focus.
      </Body>
      <Body>
        Currently, I wear the hat of the founding Technical Lead at Borderless,
        where I lead an exceptional team in the mission to redefine global
        payroll. My primary focus is on building cutting-edge Real-Time Payment
        systems that empower our customers to move their money across borders in
        mere minutes, revolutionizing the way we handle international
        transactions and eliminating many barriers for our customers that live
        across the world from their employer.
      </Body>
      <Body>
        Before my role at Borderless, I embarked on a thrilling journey with
        PayPay, Japan's leading cashless payments solution. It was an incredible
        experience being part of the team during the meteoric rise of this
        payment platform, complete with its moments of exhilaration and, yes, a
        touch of madness. These adventures provided me with invaluable insights
        into scaling technical solutions to their limits, along with a treasure
        trove of developer experience tools.
      </Body>
      <Body>
        Join me as I continue to explore the ever-evolving world of technology,
        sharing insights, tips, and stories from my exciting career. Whether
        you're a fellow developer, an aspiring engineer, or just someone curious
        about the digital landscape, I hope my experiences can inspire and
        inform your own journey.
      </Body>
      <Body>
        Feel free to reach out and connect, as I'm always excited to engage with
        fellow tech enthusiasts and thought leaders. Let's embark on this
        digital adventure together!
      </Body>
    </Container>
  );
};
