import { Row } from "../components/Row";
import avatar from "../assets/avatar.jpg";
import { CircularImage } from "../components/CircularImage";
import { styled } from "styled-components";
import { Column } from "../components/Column";
import { Subtitle, Title } from "../components/Typography";
import GitHubIcon from "@mui/icons-material/GitHub";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Container = styled(Column)`
  width: 100%;
`;

const AvatarRow = styled(Row)`
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  justify-content: center;
`;

const Avatar = styled(CircularImage)`
  width: 200px;
`;

const NameRow = styled(Row)`
  justify-content: center;
`;

export const Root = () => {
  return (
    <Container>
      <AvatarRow>
        <Avatar src={avatar}></Avatar>
      </AvatarRow>
      <NameRow>
        <Title>{"Thomas Santerre"}</Title>
      </NameRow>
      <NameRow>
        <Subtitle>
          {"Engineering Leader, Full Stack Developer and Cat Enthusiast"}
        </Subtitle>
      </NameRow>
      <NameRow>
        <IconButton
          type="button"
          component={Link}
          to="https://github.com/tomsanbear/"
          target="_blank"
          referrerPolicy="no-referrer"
        >
          <GitHubIcon />
        </IconButton>
        <IconButton
          component={Link}
          to="https://www.linkedin.com/in/tomsanbear/"
          target="_blank"
          referrerPolicy="no-referrer"
        >
          <LinkedInIcon />
        </IconButton>
      </NameRow>
    </Container>
  );
};
